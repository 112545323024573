import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { ActionComponent, BodyComponent, HeaderComponent, TableComponent } from "@comps/components";
import { formatCompleteDataTime, makeRequest, notify, removeById, replaceById } from "@utils/helpers";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
// import CreateUser from "./components/CreateUser";
import { deleteUser, editUser, getUsers, resetAll } from "./requests";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { Button, Col, Input, Row, Space, Switch, Table, Tag, Tooltip } from "antd";



const IndexUser = () => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [resetLoader, setResetLoader] = useState(false);
  const [selectionType, setSelectionType] = useState('checkbox');
  const [dataSource, setDataSource] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    sortName: 'id',
    sortType: 'desc'
  });
  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      name: `Edward King ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}`,
    });
  }
  const [childComponent, setChildComponent] = useState(null);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const changeStatus = (checked, object) => {

    const payload = {
      id: object._id,
      test: checked
    }

    makeRequest(setLoader, editUser, payload, onUpdateSuccess, onError)
  }
  const onUpdateSuccess = (response, msg) => {
    notify("Update Successfuly")
  }

  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstName',
      ...getColumnSearchProps('firstName'),
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName',
      ...getColumnSearchProps('lastName'),
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      ...getColumnSearchProps('email'),

    },
    {
      key: 'country',
      title: 'Country',
      dataIndex: 'country',
      ...getColumnSearchProps('country'),

    },
    {
      key: 'city',
      title: 'City',
      dataIndex: 'city',
      ...getColumnSearchProps('city'),

    },

    {
      key: 'postalCode',
      title: 'Postal Code',
      dataIndex: 'postalCode',
      ...getColumnSearchProps('postalCode'),


    },
    {
      key: 'age',
      title: 'Age',
      dataIndex: 'age',
      sorter: (a, b) => a.age - b.age,



    },
    {
      key: 'gender',
      title: 'Gender',
      dataIndex: 'gender',
      sorter: (a, b) => {
        const genderA = a.gender || ""; // use empty string if a.gender is falsy (e.g. null or undefined)
        const genderB = b.gender || ""; // use empty string if b.gender is falsy (e.g. null or undefined)
        return genderA.localeCompare(genderB);
      }



    },
    {
      key: 'device',
      title: 'Device',
      dataIndex: 'device',

      render: (device) => device ? device : "- - -"


    },
    {
      key: 'version',
      title: 'Version',
      dataIndex: 'version',
      render: (version) => version ? version : "- - -"

    },
    {
      key: 'createdAt',
      title: 'Acct Created',
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (created_at) => {
        const date = new Date(created_at);
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
      }
    },
    {
      key: 'eventsCount',
      title: 'Races',
      dataIndex: 'eventsCount',
      sorter: (a, b) => a.eventsCount - b.eventsCount,


    },
    {
      key: 'badgesCount',
      title: 'Badges',
      dataIndex: 'badgesCount',
      sorter: (a, b) => a.badgesCount - b.badgesCount,

    },
    {
      key: 'subscriptions',
      title: 'Subscriptions',
      dataIndex: 'subscriptions',
      sorter: (a, b) => {
        const genderA = a.subscriptions[0]?.subscriptionType || ""; // use empty string if a.gender is falsy (e.g. null or undefined)
        const genderB = b.subscriptions[0]?.subscriptionType || ""; // use empty string if b.gender is falsy (e.g. null or undefined)
        return genderA.localeCompare(genderB);
      },
      render: (subscriptions) => {
        return subscriptions[0]?.subscriptionType ? subscriptions[0]?.subscriptionType : 'No subscriptions';
      }
    },
    // {
    //   key: 'test',
    //   title: 'Test',
    //   render: (test, object) => (
    //     <Switch defaultChecked={object.test} onChange={(checked) => changeStatus(checked, object)} />

    //   )
    // },

    {
      key: "actions",
      title: 'Achievements',
      render: (record) => ActionComponentEx(record)
    },
  ];
  const ActionComponentEx = (record) => {
    let icon = null;
    if (record) {
      if (record.is_active) {
        icon = <CloseOutlined className="icon-style da-text-color-danger-1" />;
      } else {
        icon = <CheckOutlined className="icon-style da-text-color-success-1" />;
      }
    }
    return (
      <ActionComponent each={record} onView={onView} onDelete={onDelete} >
      </ActionComponent>
    );
  }

  useEffect(() => {
    getAllUsers();
    // eslint-disable-next-line
  }, []);

  const getAllUsers = () => {
    let payload = {
      start: pagination.current - 1,
      length: pagination.pageSize,
      sort_name: pagination.sortName,
      sort_type: pagination.sortType,

    };
    makeRequest(setLoader, getUsers, payload, onSuccess, null);
  }


  const onSuccess = (response) => {
    const updatedResponse = response.filter(obj => obj.email !== "admin@gmail.com")
      .map(obj => ({ ...obj, key: obj._id }));
    setDataSource(updatedResponse);

  }






  const onView = (record) => {
    history.push(`/user-management/users/view/${record._id}`);
  }


  const onDelete = (record) => {
    const payload = {
      id: [record._id]
    }
    debugger
    makeRequest(setLoader, deleteUser, payload, onDeleteSuccess,
      onError)
  }

  const onDeleteSuccess = (response, msg) => {
    getAllUsers();
    notify("Delete successfuly")
  }

  const onError = (error, msg) => {
    notify("Network Error")
  }
  const pageConfig = {
    headers: {
      title: <h2 style={{ color: "#0093CD" }} >Total Users: {dataSource.length},  IOS Users: {dataSource?.filter((obj) => obj.device.toLowerCase() == "ios").length}, Android Users: {dataSource?.filter((obj) => obj.device.toLowerCase() == "android").length}</h2>,
      breadcrumb: [
        {
          name: "Users",
          // path: "/user-management/users"
        }
      ]
    }
  }
  const resetAllBadges = () => {
    let payload = {
      resetUsers: selectedRowKeys
    }
    makeRequest(setResetLoader, resetAll, payload, restSuccessed, onError)
  }
  const restSuccessed = (response, msg) => {
    notify("Reset Successfuly")
    getAllUsers()
  }
  return (
    <>
      {childComponent}
      <HeaderComponent headers={pageConfig.headers}>
      </HeaderComponent>
      <BodyComponent>
        {/* <FilterComponent filters={availableFilters} onFilter={setFilters} api={getFilters} /> */}
        <Col span={24} >
          <Row justify="end" className="da-m-24" >
            <h5 className="da-m-12" >Do not reload and close the page until procees is complete</h5>
            <Button loading={resetLoader || loader} danger onClick={resetAllBadges}  >Reset  Badges</Button>
          </Row>
        </Col>
        <Table
          rowSelection={rowSelection}
          columns={columns} dataSource={dataSource}
          loading={loader}
          scroll={{
            x: 1500,

          }}
        />
      </BodyComponent>
    </>
  );
}

export default IndexUser;

const availableFilters = [
  {
    key: 'name',
    placeholder: 'User Name',
    type: 'text',
  },
  {
    key: 'created_at',
    placeholder: 'Creation Date',
    type: 'date',
  },
  {
    key: 'is_active',
    placeholder: 'Select Status',
    type: 'select',
    data_key: 'actives'
  },
  {
    key: 'role_id',
    placeholder: 'Select Role',
    type: 'select',
    data_key: 'all_roles',
  }
];
